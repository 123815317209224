import React from "react"
import { Link } from "gatsby"
import style from "./customBlocks.module.css"
import "../../__generated__/gatsby-types"

const serializers = {
    types: {
        button: (props: {node: GatsbyTypes.SanityButton}) => {
            const button = props.node
            return(
                <div className={style.buttonContainer}>
                    <Link to={button.page.slug && `/${button.page.slug.current}/`} className={style.button}>
                        {button.title}
                    </Link>
                </div>
            )
        }
    }
}

export default serializers;